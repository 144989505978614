import React from 'react';
import { Link } from 'gatsby';
import ActionButton from './../../ActionButton';
import {
    InputContainer,
    PrivacyNotice,
    StyledForm,
    FormWrapper
} from './styledComponents';

const ContactForm = () => {
    return (
        <FormWrapper>
            <StyledForm
                action="https://account.aquaplot.com/contact_form"
                method="post"
                acceptCharset="UTF-8"
            >
                <InputContainer> 
                    <p>Please fill out all required fields (marked *).</p> 
                    <input name="contact_inquiry[hp]" type="hidden"/> {/* honeypot #1 */}
                    <input name="contact_inquiry[web]" style={{        
                        position: 'absolute',
                        top: -1000
                    }}/> {/* honeypot #2 */}
                    <label htmlFor="contact_inquiry_name">Name *</label> 
                    <input placeholder=" Name" name="contact_inquiry[name]" id="contact_inquiry_name"  /> 
                    
                    <label htmlFor="contact_inquiry_email">E-Mail *</label> 
                    <input placeholder=" E-Mail" name="contact_inquiry[email]" id="contact_inquiry_email" /> 
                    
                    <label htmlFor="contact_inquiry_company">Company</label> 
                    <input placeholder=" Company" name="contact_inquiry[company]" id="contact_inquiry_company" /> 

                    <label htmlFor="contact_inquiry_comment">Your Message *</label> 
                    <textarea placeholder=" Your Message" rows="5" label="Your Message" name="contact_inquiry[comment]" id="contact_inquiry_comment" /> 

                    <ActionButton actionType="button" type="submit" name="commit">Send Message</ActionButton>

                    <PrivacyNotice>
                        <p>We handle your data with care. Please see our <Link to="/privacy">Privacy policy</Link>.</p> 
                    </PrivacyNotice>
                </InputContainer>
            </StyledForm>
        </FormWrapper>
    );
}

export default ContactForm;