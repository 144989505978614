import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/hgr/ws/website-js/src/templates/infoDocTemplateMdx.js"
import ContactForm from './../components/Forms/Contact'
export const frontmatter = {
  path: "/contact",
  date: "2018-10-06",
  title: "Contact",
}
export default ({components, ...props}) => <MDXTag name="wrapper" Layout={DefaultLayout} layoutProps={props} components={components}>



<ContactForm /></MDXTag>

export const _frontmatter = {};

  