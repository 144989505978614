import styled from 'styled-components';

export const InputContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    margin: 10px;
    flex: 1 1 auto;

    input,
    textarea{
        margin-bottom: .75em;
        border: 1px solid lightgray;
    }
`

export const PrivacyNotice = styled.div`
    font-size: small;
    text-align: center;
`

export const StyledForm = styled.form`
    width: 100vw;
    max-width: 100%;
`

export const FormWrapper = styled.div`
    max-width: 700px;
    margin: 0 auto;
`